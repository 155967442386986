import React from "react"
import Loader from "../loaders/loader"
const Area = ({ placeholder, value, onChange, loading }) => {
  if (loading) return <Loader />
  return (
    <textarea className="outline-none text-sm p-2 placeholder-gray-800 border" rows={4} placeholder={placeholder} value={value} onChange={onChange} />
  )
}

export default Area
