import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Pagination from "../../base/paginated-table/pagination"
import Subtitle from "../../layout/subtitle"
import Area from "../../base/forms/area"
import Label from "../../base/forms/label"
import Button from "../../base/buttons/regular"
import { hrManagersSelector } from "../../../store/hrManagers/selectors"
import User from "./components/user"
import { parseHrManagers, divideDataOverPages, getTitles, getEmailIntro, getName, parseCode } from "./utils/utils"
import Loader from "../../base/loaders/loader"
import Languages from "./components/languages"
import { getLanguageCode } from "../../../utils/language"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import useCreateStrings from "../../../utils/hooks/useCreateStrings"
import { fetchStrings } from "../../../store/strings/actions"
import { stringsSelector } from "../../../store/strings/selectors"
import notifications from "../../../utils/notifications"
import CreateHrAdminModal from "./components/createHrAdminModal"
import ReactTooltip from "react-tooltip"
import { IoIosHelpCircle } from "react-icons/io"
import TextInput from "../../base/forms/textInput"
import shortid from "shortid"
import Dropdown from "../../base/dropdown"
import { useCurrentCompany } from "src/utils/hooks/useCurrentCompany"
import { getLevelEvaluationTypeNameForType } from "src/utils/evaluation-type-utils"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  comingSoonTooltip: "Coming Soon!",
  newStaticString: "Create new!",
  staffMembersTitle: "Staff members",
  contentTitle: "Content",
  saveButon: "Save",
  saveStringsSuccessNotificationTitle: "Saved!",
  saveStringsErrorNotificationMessage: "The text is now updated.",
  saveStringsErrorNotificationTitle: "Oops something went wrong!",
  createNewAdminButton: "Create new admin",
  Template: "Pick a template",
  testsLeftTitle: "Quota",
  contentTooltip:
    "Edit the content of the invitation email send out to the test takers. Here you are able to modify the content of the email send out to the test takers in different languages.",
})

const Settings = ({ companyUUID }) => {
  const formatMessage = useFormatMessage()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguageCode("EN"))
  const [inCreate, setInCreate] = useState(false)
  const [selectedIntroduction, setSelectedIntroduction] = useState({ code: undefined, texten: "", textfr: "", textnl: "", textde: "" })
  const [stringsInState, setStringsInState] = useState(undefined)
  const { loading: stringsLoading, results: strings } = useSelector(stringsSelector(companyUUID))

  const { createStaticStrings } = useCreateStrings()
  const dispatch = useDispatch()

  const company = useCurrentCompany()

  const { regular } = useThemeColors()

  useEffect(() => {
    dispatch(fetchStrings(companyUUID, selectedLanguage?.toLowerCase()))
  }, [companyUUID])

  useEffect(() => {
    if (strings) {
      setStringsInState(strings)
    }
    if (!!strings && strings.length > 0 && !selectedIntroduction.code) {
      setSelectedIntroduction({ ...strings[0] })
    }
  }, [strings])

  /* get all persons from state */
  const { results, loading } = useSelector(hrManagersSelector(companyUUID))

  /* Filter out HR managers from persons */
  let hrManagers
  if (results && results.length > 0) {
    hrManagers = parseHrManagers(results)
    hrManagers = divideDataOverPages(hrManagers, 5)
  }

  const titles = getTitles(selectedLanguage)
  const onSubmitStaticStrings = async () => {
    try {
      if (!selectedIntroduction.code || getName(selectedIntroduction) === "") {
        throw Error("Please chose a name for the introductions.")
      }
      // await createStaticStrings(
      //   companyUUID,
      //   {
      //     [`text${selectedLanguage.toLowerCase()}`]: testIntro,
      //     companyuuid: companyUUID,
      //     code: "evaluation.web.intro",
      //   },
      //   selectedLanguage.toLowerCase()
      // )
      await createStaticStrings(
        companyUUID,
        {
          ...selectedIntroduction,
          companyuuid: companyUUID,
          code: selectedIntroduction.code ? selectedIntroduction.code : `${shortid.generate()}.${selectedIntroduction.name}`,
        },
        selectedLanguage.toLowerCase()
      )
      notifications.success(formatMessage(messages.saveStringsSuccessNotificationTitle), formatMessage(messages.saveStringsErrorNotificationMessage))
    } catch (err) {
      notifications.error(formatMessage(messages.saveStringsErrorNotificationTitle), err.message)
    }
  }

  return (
    <div className="flex flex-col ">
      <div className="w-full bg-white shadow sm:rounded">
        <div className={`h-1  bg-${regular} sm:rounded-t`} />
        <div className="p-8 sm:px-8">
          <Subtitle>
            <div className="flex items-baseline">
              {formatMessage(messages.contentTitle)}
              <ReactTooltip id="content-tooltip" place="top" type="info" effect="solid">
                {formatMessage(messages.contentTooltip)}
              </ReactTooltip>
              <a data-for="content-tooltip" data-tip>
                <IoIosHelpCircle className="ml-1" size={16} />
              </a>
              {stringsInState && (
                <Dropdown
                  options={stringsInState.map(string => {
                    return { text: getName(string), onClick: () => setSelectedIntroduction({ ...string, name: getName(string) }) }
                  })}
                  title={
                    selectedIntroduction.code && getName(selectedIntroduction) ? getName(selectedIntroduction) : formatMessage(messages.Template)
                  }
                />
              )}
            </div>
          </Subtitle>

          <Label name={titles.name} className="mt-2 -mr-1">
            <TextInput
              placeholder={titles.name}
              value={getName(selectedIntroduction)}
              onChange={e => setSelectedIntroduction({ ...selectedIntroduction, code: parseCode(selectedIntroduction, e.target.value) })}
            />
          </Label>
          <Languages className="pt-1 mt-2" setSelectedLanguage={setSelectedLanguage} selectedLangauge={selectedLanguage} />
          <div className="flex flex-col pb-6">
            {/* <Label name={titles.test} className="mb-2" />
            <Area placeholder={titles.testPlaceHolder} value={testIntro} onChange={e => setTestIntro(e.target.value)} loading={stringsLoading} /> */}
            <Label name={titles.email} className="mb-2 mt-2" />
            <Area
              placeholder={titles.emailPlaceHolder}
              value={getEmailIntro(selectedIntroduction, selectedLanguage.toLowerCase())}
              onChange={e => setSelectedIntroduction({ ...selectedIntroduction, [`text${selectedLanguage.toLowerCase()}`]: e.target.value })}
              loading={stringsLoading}
            />
            <div className="flex flex-row justify-end">
              <Button primary onClick={onSubmitStaticStrings}>
                {formatMessage(messages.saveButon)}
              </Button>
              <Button
                primary
                className="ml-2"
                onClick={() => setSelectedIntroduction({ code: undefined, texten: "", textfr: "", textnl: "", textde: "" })}
              >
                +
              </Button>
            </div>
          </div>
          <Subtitle>{formatMessage(messages.testsLeftTitle)}</Subtitle>
          <div className="py-4 mb-4">
            {Object.keys(company?.maxevaluationtypes ?? {}).map(key => (
              <div className="text-sm" key={key}>
                <span className="font-medium">{_.upperFirst(getLevelEvaluationTypeNameForType(key))}:</span>{" "}
                {Math.max((company?.unusedevaluationtypes ?? {})[key], 0)} available
              </div>
            ))}
          </div>
          <Subtitle>{formatMessage(messages.staffMembersTitle)}</Subtitle>
          <div className="py-1">
            {hrManagers &&
              hrManagers.length > 0 &&
              hrManagers[currentPage - 1].map(hrm => (
                <User
                  key={hrm.uuid}
                  hrManagerObject={hrm.originalHrManager}
                  name={hrm.fullname}
                  phone={hrm.phone}
                  email={hrm.email}
                  personUUID={hrm.uuid}
                  companyUUID={companyUUID}
                />
              ))}
            {!results && loading && (
              <div className="w-full flex items-center justify-center">
                <Loader name="ball-spin-fade-loader" />
              </div>
            )}
            <CreateHrAdminModal companyUUID={companyUUID} visible={inCreate} onClose={() => setInCreate(false)} className="bg-white" />
            <div className="flex justify-end items-center mt-4">
              <Button primary onClick={() => setInCreate(true)}>
                {formatMessage(messages.createNewAdminButton)}
              </Button>
            </div>
            <div className="w-full flex justify-center my-5">
              {hrManagers && <Pagination numberOfPages={hrManagers.length} currentPage={currentPage} onSelectPageNumber={setCurrentPage} />}
            </div>
          </div>
          <div className="text-center text-xs mt-10 text-gray-700">Copyright &copy; Atolo 2022</div>
        </div>
      </div>
    </div>
  )
}

export default Settings
