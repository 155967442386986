import React, { useState } from "react"
import { FiTrash2 } from "react-icons/fi"
import ConfirmModal from "../../../base/modals/confirmModal"
import Button from "../../../base/buttons/icon"
import ReactTooltip from "react-tooltip"
import notifications from "../../../../utils/notifications"

import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import { PencilAltIcon } from "@heroicons/react/outline"
import EditAdminModal from "../../../../components/atolo/admins/components/editAdminModal"
import usePatchPerson from "src/utils/hooks/usePatchPerson"

const messages = defineMessages({
  removeTooltip: "Deactivate",
  deleteAdminModalMessage: "Are you sure you want to deactivate this admin?",
  deleteAdminModalButton: "Delete",
  editAdminButtonLabel: "edit",
  deactivateAdminButtonLabel: "deactivate",
})

const User = ({ name, email, personUUID, companyUUID, hrManagerObject }) => {
  const [inDelete, setInDelete] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { patchPerson, loading } = usePatchPerson()

  const formatMessage = useFormatMessage()

  const remove = async () => {
    try {
      await patchPerson({ ...hrManagerObject, ishrmanager: -1 })
      notifications.success("Person deleted!", "You have successfully deleted this person.")
      setInDelete(false)
      window?.location?.reload()
    } catch (err) {
      notifications.error("Oops! Something went wrong.", err.message, err.details)
    }
  }
  return (
    <div className="flex flex-row items-center my-1" key={personUUID}>
      <div className="flex-1 text-sm truncate pr-4">{name}</div>
      <a className="flex-1 text-sm text-blue-700 truncate pr-4" href={`mailto:${email}`}>
        {email}
      </a>
      <Button onClick={() => setIsEditing(true)}>
        <span className="text-xs">{formatMessage(messages.editAdminButtonLabel)}</span>
      </Button>
      <a data-for="remove-user" data-tip>
        <Button type="error" onClick={() => setInDelete(true)} loading={loading}>
          <span className="text-xs">{formatMessage(messages.deactivateAdminButtonLabel)}</span>
        </Button>
      </a>
      {isEditing && (
        <EditAdminModal
          onClose={() => {
            setIsEditing(false)
          }}
          withPageReload
          person={hrManagerObject}
        />
      )}
      <ConfirmModal
        visible={inDelete}
        title={formatMessage(messages.deleteAdminModalMessage)}
        confirmText={formatMessage(messages.deactivateAdminButtonLabel)}
        onConfirm={remove}
        onClose={() => setInDelete(false)}
      />
    </div>
  )
}

export default User
