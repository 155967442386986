import React, { useEffect, useState } from "react"
import Settings from "../../components/admin/settings"
import AdminLoginGuard from "../../components/admin/AdminLoginGuard"
import Layout from "../../components/layout"
import { useDispatch } from "react-redux"
import { fetchHRManagers } from "../../store/hrManagers/actions"
import useUser from "../../utils/hooks/useUser"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  const [companyUUID, setCompanyUUID] = useState("")
  const user = useUser()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && user.company) {
      setCompanyUUID(user.company.uuid)
      dispatch(fetchHRManagers(user.company.uuid))
    }
  }, [user])

  return (
    <AdminLoginGuard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Settings</title>
      </Helmet>
      <Layout type="hr">
        <div className="h-full w-full flex-1 max-w-4xl w-4/5 md:pt-8 md:pb-20">
          <Settings companyUUID={companyUUID} />
        </div>
      </Layout>
    </AdminLoginGuard>
  )
}

export default IndexPage
