import React, { useState } from "react"
import ModalContainer from "../../../base/modals/modalContainer"
import useCreatePerson from "../../../../utils/hooks/useCreatePerson"
import { v4 as uuidv4 } from "uuid"
import notifications from "../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import Button from "../../../base/buttons/regular"
import Label from "../../../base/forms/label"
import TextInput from "../../../base/forms/textInput"
import Title from "../../../layout/title"
import { getPersonByEmail, getPersonList } from "src/api"

const messages = defineMessages({
  createAdminSuccessNotificationTitle: "You have created a new HR admin",
  createAdminSuccessNotificationMessage: "The new admin will receive an email to set his password.",
  createAdminErrorNotificationTitle: "Oops! Something went wrong",
  createAdminButtonTooltip: "Add admin",
  createAdminFirstNameLabel: "First name",
  createAdminLastNameLabel: "Last name",
  createAdminEmailLabel: "Email",
  createAdminTitle: "New Admin",
})

const CreateHrAdminModal = ({ companyUUID, visible, onClose }) => {
  const [person, setPerson] = useState({
    fullname: "",
    email: "",
  })

  const { loading, createPerson } = useCreatePerson()
  const formatMessage = useFormatMessage()

  const onChange = e => {
    setPerson({ ...person, [e.target.name]: e.target.value })
  }

  const handler = async () => {
    const personUUID = uuidv4()
    try {
      await createPerson(
        {
          uuid: personUUID,
          firstname: person.firstname,
          lastname: person.lastname,
          commonname: `${person.firstname} ${person.lastname}`,
          email: person.email,
          isevaluatedperson: false,
          ishrmanager: true,
          companyuuid: companyUUID,
          isetolo: false,
          commlangcode: "nl",
        },
        companyUUID
      )
      onClose()
      setPerson({
        firstname: "",
        lastname: "",
        email: "",
      })
      notifications.success(
        formatMessage(messages.createAdminSuccessNotificationTitle),
        formatMessage(messages.createAdminSuccessNotificationMessage)
      )
    } catch (err) {
      notifications.error(formatMessage(messages.createAdminErrorNotificationTitle), err.message, err.details)
    }
  }

  return (
    <ModalContainer className="w-96" visible={visible} onClose={onClose}>
      <div className="px-10 pb-12">
        <Title>{formatMessage(messages.createAdminTitle)}</Title>

        <div className="flex flex-col justify-between mt-2">
          <Label className="mb-4" name={formatMessage(messages.createAdminFirstNameLabel)}>
            <TextInput name="firstname" value={person.firstname} onChange={onChange} placeholder="John" />
          </Label>
          <Label className="mb-4" name={formatMessage(messages.createAdminLastNameLabel)}>
            <TextInput name="lastname" value={person.lastname} onChange={onChange} placeholder="Doe" />
          </Label>
          <Label className="mb-4" name={formatMessage(messages.createAdminEmailLabel)}>
            <TextInput name="email" value={person.email} onChange={onChange} placeholder="new@az.be" />
          </Label>
          <Button primary className="mt-4" onClick={handler} loading={loading}>
            {formatMessage(messages.createAdminButtonTooltip)}
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default CreateHrAdminModal
