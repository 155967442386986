import { useState } from "react"
import { useDispatch } from "react-redux"
import { fetchStrings } from "../../store/strings/actions"
import { createStaticStrings } from "src/api"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"

export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const execute = async (companyUUID, strings, language, uuid) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("CREATE_STATIC_STRINGS"))
      setLoading(true)
      setError(undefined)
      const data = await createStaticStrings(language.toLowerCase(), uuid, strings)
      dispatch(fetchStrings(companyUUID, language))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, createStaticStrings: execute }
}
