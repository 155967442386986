import React from "react"
import { useThemeColors } from "src/utils/hooks/useThemeColors"
import { getLanguageCode, getLanguageName } from "../../../../utils/language"

const Languages = ({ className, setSelectedLanguage, selectedLangauge }) => {
  const { regular } = useThemeColors()
  return (
    <div className={`flex text-sm ${className}`}>
      <span
        className={`mr-3 ${selectedLangauge === getLanguageCode("EN") ? `text-${regular} ` : "text-black"} cursor-pointer`}
        onClick={() => setSelectedLanguage(getLanguageCode("EN"))}
      >
        {getLanguageName("EN")}
      </span>
      <span
        className={`mr-3 ${selectedLangauge === getLanguageCode("FR") ? `text-${regular} ` : "text-black"} cursor-pointer`}
        onClick={() => setSelectedLanguage(getLanguageCode("FR"))}
      >
        {getLanguageName("FR")}
      </span>
      <span
        className={`mr-3 ${selectedLangauge === getLanguageCode("NL") ? `text-${regular} ` : "text-black"} cursor-pointer`}
        onClick={() => setSelectedLanguage(getLanguageCode("NL"))}
      >
        {getLanguageName("NL")}
      </span>
      <span
        className={`mr-3 ${selectedLangauge === getLanguageCode("DE") ? `text-${regular} ` : "text-black"} cursor-pointer`}
        onClick={() => setSelectedLanguage(getLanguageCode("DE"))}
      >
        {getLanguageName("DE")}
      </span>
    </div>
  )
}

export default Languages
